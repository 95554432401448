import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import RightArrowButton from '../../assets/right-arrow-button.svg';
import RightArrowFadedButton from '../../assets/right-faded-arrow-button.svg';
import LeftArrowButton from '../../assets/left-arrow-button.svg';
import LeftArrowButtonFadedButton from '../../assets/left-faded-arrow-button.svg';
import {
  carouselContainer,
  carouselButtons,
  leftCarouselArrow,
  rightCarouselArrow,
} from '../ImageCarousel/styles.module.css';

import { ImageCarouselData } from '../../interfaces/image-carousel';

const ImageCarousel = ({
  imageCarouselArray,
  currentCarouselImageState,
  setCurrentCarouselImageState,
}: ImageCarouselData) => {
  const nextButton = () => {
    imageCarouselArray = imageCarouselArray.filter((img: any) => {
      return img.image;
    });
    if (currentCarouselImageState != imageCarouselArray.length - 1) {
      setCurrentCarouselImageState(currentCarouselImageState + 1);
    }
  };

  const backButton = () => {
    if (currentCarouselImageState != 0) {
      setCurrentCarouselImageState(currentCarouselImageState - 1);
    }
  };

  return (
    <div className={carouselContainer}>
      <GatsbyImage
        image={imageCarouselArray[currentCarouselImageState].image}
        alt={imageCarouselArray[currentCarouselImageState].alt}
      />
      <div className={carouselButtons}>
        <button className={leftCarouselArrow} onClick={backButton}>
          {currentCarouselImageState === 0 ? (
            <LeftArrowButtonFadedButton />
          ) : (
            <LeftArrowButton />
          )}
        </button>
        <button className={rightCarouselArrow} onClick={nextButton}>
          {currentCarouselImageState === imageCarouselArray.length - 1 ? (
            <RightArrowFadedButton />
          ) : (
            <RightArrowButton />
          )}
        </button>
      </div>
    </div>
  );
};

export default ImageCarousel;
