// extracted by mini-css-extract-plugin
export var carouselModal = "styles-module--carousel-modal--dd03e";
export var carouselModalActive = "styles-module--carousel-modal-active--c53d5";
export var completionYearStatus = "styles-module--completion-year-status--6e11b";
export var completionYearTitle = "styles-module--completion-year-title--ea47f";
export var container = "styles-module--container--f3a99";
export var fadeInContent = "styles-module--fadeInContent--d44d5";
export var fifthDetailsImage = "styles-module--fifth-details-image--4d688";
export var fifthProjectDetailsImage = "styles-module--fifth-project-details-image--2a90a";
export var firstDetailsImage = "styles-module--first-details-image--caf24";
export var firstProjectDetailsImage = "styles-module--first-project-details-image--14cde";
export var footerProjectDetailsImage = "styles-module--footer-project-details-image--0f725";
export var fourthDetailsImage = "styles-module--fourth-details-image--7ec91";
export var fourthProjectDetailsImage = "styles-module--fourth-project-details-image--3d3a2";
export var middleProjectDetailsImage = "styles-module--middle-project-details-image--740ce";
export var portfolioBackButton = "styles-module--portfolio-back-button--9a5fe";
export var portfolioContainer = "styles-module--portfolio-container--d57d4";
export var portfolioDetail = "styles-module--portfolio-detail--f73f7";
export var portfolioDetailDescription = "styles-module--portfolio-detail-description--7a405";
export var portfolioDetailInfo = "styles-module--portfolio-detail-info--69050";
export var portfolioDetailPageContainer = "styles-module--portfolio-detail-page-container--3110c";
export var portfolioDetailTitle = "styles-module--portfolio-detail-title--5c683";
export var portfolioDetailTitleImage = "styles-module--portfolio-detail-title-image--4e617";
export var portfolioProjectName = "styles-module--portfolio-project-name--c73b9";
export var projectDetailsFooterImage = "styles-module--project-details-footer-image--7b12b";
export var projectDetailsMiddleImage = "styles-module--project-details-middle-image--a44c3";
export var projectInfo = "styles-module--project-info--1fd24";
export var projectInfoBox = "styles-module--project-info-box--4e196";
export var projectInfoContainer = "styles-module--project-info-container--91c60";
export var projectInfoLowerContainer = "styles-module--project-info-lower-container--582b5";
export var projectInfoLowerSection = "styles-module--project-info-lower-section--4dbc0";
export var projectInfoSection = "styles-module--project-info-section--691c8";
export var projectSizeStatus = "styles-module--project-size-status--b7b64";
export var projectSizeTitle = "styles-module--project-size-title--476f8";
export var projectStatus = "styles-module--project-status--d6fb6";
export var projectStatusTitle = "styles-module--project-status-title--64089";
export var secondDetailsImage = "styles-module--second-details-image--61a78";
export var secondProjectDetailsImage = "styles-module--second-project-details-image--44bfe";
export var seventhDetailsImage = "styles-module--seventh-details-image--9f03a";
export var seventhProjectDetailsImage = "styles-module--seventh-project-details-image--a9158";
export var sixthDetailsImage = "styles-module--sixth-details-image--23437";
export var sixthProjectDetailsImage = "styles-module--sixth-project-details-image--5e40f";
export var thirdDetailsImage = "styles-module--third-details-image--d2f88";
export var thirdProjectDetailsImage = "styles-module--third-project-details-image--cdb0d";