import React, { useState, useRef, MutableRefObject } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicRichText, JSXMapSerializer } from '@prismicio/react';
import BackgroundImage from 'gatsby-background-image';
import ImageCarousel from '../components/ImageCarousel';
import Loading from '../components/Loading';
import { useAnimationLoading } from '../hooks/use-animation-loading-query';
import BackButtonArrow from '../assets/project-details-page-back-button.svg';
import Layout from '../components/Layout';
import {
  container,
  portfolioDetailPageContainer,
  portfolioContainer,
  portfolioBackButton,
  portfolioDetail,
  portfolioDetailTitle,
  portfolioDetailTitleImage,
  portfolioDetailInfo,
  portfolioDetailDescription,
  portfolioProjectName,
  projectInfoSection,
  projectInfoBox,
  projectInfo,
  projectStatusTitle,
  projectStatus,
  completionYearTitle,
  completionYearStatus,
  projectSizeTitle,
  projectSizeStatus,
  firstProjectDetailsImage,
  firstDetailsImage,
  secondProjectDetailsImage,
  secondDetailsImage,
  thirdProjectDetailsImage,
  thirdDetailsImage,
  fourthProjectDetailsImage,
  fourthDetailsImage,
  fifthProjectDetailsImage,
  fifthDetailsImage,
  sixthProjectDetailsImage,
  sixthDetailsImage,
  seventhProjectDetailsImage,
  seventhDetailsImage,
  projectInfoContainer,
  projectDetailsFooterImage,
  footerProjectDetailsImage,
  carouselModal,
  carouselModalActive,
  projectDetailsMiddleImage,
  middleProjectDetailsImage,
  projectInfoLowerContainer,
  projectInfoLowerSection,
} from '../styles/pages/portfolio-detail/styles.module.css';

import { PortfolioDetailData } from '../interfaces/portfolio-detail';
import Footer from '../components/Footer';

const FirstPortfolioDetailPage = (props: PortfolioDetailData) => {
  const {
    project_detail_name,
    project_category,
    portfolio_detail_description,
    upper_background,
    project_info,
    project_status_title,
    project_status,
    completion_year_title,
    completion_year_status,
    project_size_title,
    project_size_status,
    first_image,
    second_image,
    third_image,
    middle_image,
    fourth_image,
    fifth_image,
    sixth_image,
    seventh_image,
    footer_image,
  } = props?.data?.prismicPortfolioDetailsPage?.data;

  const upperBackgroundImage = upper_background?.fluid;
  const firstProjectImage = first_image?.gatsbyImageData;
  const secondProjectImage = second_image?.gatsbyImageData;
  const thirdProjectImage = third_image?.gatsbyImageData;
  const middleProjectImage = middle_image?.gatsbyImageData;
  const fourthProjectImage = fourth_image?.gatsbyImageData;
  const fifthProjectImage = fifth_image?.gatsbyImageData;
  const sixthProjectImage = sixth_image?.gatsbyImageData;
  const seventhProjectImage = seventh_image?.gatsbyImageData;
  const footerProjectImage = footer_image?.gatsbyImageData;

  const loadingData = useAnimationLoading();

  const [isCarouselModalState, setIsCarouselModalState] = useState(false);
  const [currentCarouselImageState, setCurrentCarouselImageState] = useState(0);

  const carouselModalRef = useRef() as MutableRefObject<HTMLDivElement>;
  const carouselMobileModalRef = useRef() as MutableRefObject<HTMLDivElement>;

  const headingSerializer: JSXMapSerializer = {
    heading1: ({ children }) => (
      <h1 className={portfolioProjectName}>{children}</h1>
    ),
  };

  return (
    <Layout>
      <Loading data={loadingData} />
      <div className={container}>
        <BackgroundImage
          className={portfolioDetailPageContainer}
          Tag="div"
          fluid={upperBackgroundImage}
          alt={upper_background?.alt}
        >
          <div className={portfolioContainer}>
            <div className={portfolioDetail}>
              <div className={portfolioDetailTitleImage}>
                <Link to="/">
                  <div className={portfolioBackButton}>
                    <BackButtonArrow />
                  </div>
                </Link>
              </div>
              <div className={portfolioProjectName}>
                <PrismicRichText
                  field={project_detail_name?.richText}
                  components={headingSerializer}
                />
              </div>
            </div>
            <div className={portfolioDetailInfo}>
              <div className={portfolioDetailTitle}>
                <PrismicRichText field={project_category?.richText} />
              </div>
              <div className={portfolioDetailDescription}>
                <PrismicRichText
                  field={portfolio_detail_description?.richText}
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
        <div className={projectInfoContainer}>
          <div className={projectInfoSection}>
            <div className={projectInfoBox}>
              <div className={projectInfo}>
                <PrismicRichText field={project_info?.richText} />
              </div>
              <div className={projectStatusTitle}>
                <PrismicRichText field={project_status_title?.richText} />
              </div>
              <div className={projectStatus}>
                <PrismicRichText field={project_status?.richText} />
              </div>
              <div className={completionYearTitle}>
                <PrismicRichText field={completion_year_title?.richText} />
              </div>
              <div className={completionYearStatus}>
                <PrismicRichText field={completion_year_status?.richText} />
              </div>
              <div className={projectSizeTitle}>
                <PrismicRichText field={project_size_title?.richText} />
              </div>
              <div className={projectSizeStatus}>
                <PrismicRichText field={project_size_status?.richText} />
              </div>
            </div>
            <div
              className={firstProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(0);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={firstProjectImage}
                alt={first_image?.alt}
                className={firstDetailsImage}
              />
            </div>
            <div
              className={thirdProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(1);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={thirdProjectImage}
                alt={third_image?.alt}
                className={thirdDetailsImage}
              />
            </div>
            <div
              className={secondProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(2);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={secondProjectImage}
                alt={second_image?.alt}
                className={secondDetailsImage}
              />
            </div>
          </div>
        </div>
        <div className={middleProjectDetailsImage}>
          <GatsbyImage
            image={middleProjectImage}
            alt={middle_image?.alt}
            className={projectDetailsMiddleImage}
          />
        </div>
        <div className={projectInfoLowerContainer}>
          <div className={projectInfoLowerSection}>
            <div
              className={fourthProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(3);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={fourthProjectImage}
                alt={fourth_image?.alt}
                className={fourthDetailsImage}
              />
            </div>
            <div
              className={fifthProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(4);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={fifthProjectImage}
                alt={fifth_image?.alt}
                className={fifthDetailsImage}
              />
            </div>
            <div
              className={sixthProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(5);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={sixthProjectImage}
                alt={sixth_image?.alt}
                className={sixthDetailsImage}
              />
            </div>
            <div
              className={seventhProjectDetailsImage}
              onClick={() => {
                setCurrentCarouselImageState(6);
                setIsCarouselModalState(true);
              }}
            >
              <GatsbyImage
                image={seventhProjectImage}
                alt={seventh_image?.alt}
                className={seventhDetailsImage}
              />
            </div>
          </div>
        </div>
        <div
          className={isCarouselModalState ? carouselModalActive : carouselModal}
          ref={carouselMobileModalRef}
          onClick={(event) => {
            if (event.target !== carouselMobileModalRef.current) {
              return;
            }
            setIsCarouselModalState(false);
          }}
        >
          <div
            ref={carouselModalRef}
            onClick={(event) => {
              if (event.target !== carouselModalRef.current) {
                return;
              }
              setIsCarouselModalState(false);
            }}
          >
            <ImageCarousel
              imageCarouselArray={[
                {
                  image: firstProjectImage,
                  alt: first_image?.alt,
                },
                {
                  image: thirdProjectImage,
                  alt: third_image?.alt,
                },
                {
                  image: secondProjectImage,
                  alt: second_image?.alt,
                },
                {
                  image: fourthProjectImage,
                  alt: fourth_image?.alt,
                },
                {
                  image: fifthProjectImage,
                  alt: fifth_image?.alt,
                },
                {
                  image: sixthProjectImage,
                  alt: sixth_image?.alt,
                },
                {
                  image: seventhProjectImage,
                  alt: seventh_image?.alt,
                },
              ]}
              currentCarouselImageState={currentCarouselImageState}
              setCurrentCarouselImageState={setCurrentCarouselImageState}
            />
          </div>
        </div>
        <div className={footerProjectDetailsImage}>
          <GatsbyImage
            image={footerProjectImage}
            alt={footer_image?.alt}
            className={projectDetailsFooterImage}
          />
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PostByUid($uid: String!) {
    prismicPortfolioDetailsPage(uid: { eq: $uid }) {
      uid
      data {
        project_detail_name {
          richText
        }
        project_category {
          richText
        }
        portfolio_detail_description {
          richText
        }
        upper_background {
          alt
          fluid {
            sizes
            src
          }
        }
        project_info {
          richText
        }
        project_status_title {
          richText
        }
        project_status {
          richText
        }
        completion_year_title {
          richText
        }
        completion_year_status {
          richText
        }
        project_size_title {
          richText
        }
        project_size_status {
          richText
        }
        first_image {
          gatsbyImageData
          alt
        }
        second_image {
          gatsbyImageData
          alt
        }
        third_image {
          gatsbyImageData
          alt
        }
        middle_image {
          gatsbyImageData
          alt
        }
        fourth_image {
          gatsbyImageData
          alt
        }
        fifth_image {
          gatsbyImageData
          alt
        }
        sixth_image {
          gatsbyImageData
          alt
        }
        seventh_image {
          gatsbyImageData
          alt
        }
        footer_image {
          gatsbyImageData
          alt
        }
      }
    }
    prismicPortfolioDetail {
      data {
        portfolio_image {
          gatsbyImageData
          alt
          fluid {
            sizes
            src
            srcWebp
          }
        }
      }
    }
  }
`;

export default FirstPortfolioDetailPage;
